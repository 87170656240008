import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { SettingsWheel } from "@app/assets/Icons"

const SettingsButton = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button
      className={
        "flex w-52 cursor-pointer items-center justify-start border-none space-x-2 px-6 rounded-t-3xl pt-6 pb-4"
      }
      type="button"
      onClick={() => navigate("/settings")}
    >
      <SettingsWheel />
      <span className="text-base font-medium">
        {t("header.profile.dropdown.settings")}
      </span>
    </button>
  )
}

export default SettingsButton
