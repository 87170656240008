import React from "react"
import { HashRouter, Navigate, Outlet, Route, Routes } from "react-router-dom"
import { ChatbotProvider } from "@app/context/ChatbotContext"
import PageNotFound from "@app/uiKit/PageNotFound"
import PageWrapper from "@app/uiKit/PageWrapper"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { CircularProgress } from "@mui/material"
import * as Sentry from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"

import AccessPage from "./AccessPage/AccessPage"
import AskAiPanel from "./Dashboard/AskAiPanel"
import ConversationPanel from "./Dashboard/ConversationPanel"
import Settings from "./Settings/Settings"
import UserManagement from "./UserManagement/UserManagement"
import Dashboard from "./Dashboard"
import Header from "./Header"
import LoginScreen from "./LoginScreen"
import { queryClient } from "./queryClient"

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string // must include protocol (https://), despite documentation says its not required
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string

const App = (): JSX.Element => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${domain}/api/v2/`,
        scope: "openid profile email offline_access",
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <QueryClientProvider client={queryClient}>
        <Sentry.ErrorBoundary>
          <ChatbotProvider>
            <HashRouter>
              <AppView />
            </HashRouter>
          </ChatbotProvider>
        </Sentry.ErrorBoundary>
      </QueryClientProvider>
    </Auth0Provider>
  )
}

const AppView = (): JSX.Element => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return (
      <div className={"flex h-full items-center justify-center"}>
        <CircularProgress
          sx={() => ({
            color: "#3EAA89",
          })}
        />
      </div>
    )
  }
  return (
    <div className="flex flex-col h-full">
      <Routes>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="/access" element={<AccessPage />} />
        <Route path="login" element={<NonAuthenticatedApp />} />
        <Route path="settings" element={<Settings />} />

        <Route
          path="/"
          element={
            isAuthenticated ? <AuthenticatedApp /> : <Navigate to="/login" />
          }
        >
          <Route path="dashboard" element={<Dashboard />}>
            <Route index element={<ConversationPanel />} />
            <Route path="ask-ai" element={<AskAiPanel />} />
          </Route>
          <Route path="user-management" element={<UserManagement />} />
        </Route>
        <Route
          path="*"
          element={
            <PageWrapper>
              <PageNotFound />
            </PageWrapper>
          }
        />
      </Routes>
    </div>
  )
}

const NonAuthenticatedApp = (): JSX.Element => {
  return (
    <div className="flex flex-col h-full relative">
      <LoginScreen />
    </div>
  )
}

const AuthenticatedApp = (): JSX.Element => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default App
