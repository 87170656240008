import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useUsersQuery } from "@app/api/users/useUsersQuery"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { SortBy, SortOption, SortOrder } from "@app/model/sorting"
import { UsersParams } from "@app/model/user"
import { getPaginationPages } from "@app/services/data"
import { ThemeProvider } from "@emotion/react"
import { CircularProgress, createTheme, Pagination } from "@mui/material"
import cn from "classnames"
import { Array } from "effect"

import UsersTable from "./UsersTable"

const DEFAULT_PARAMS: UsersParams = {
  page: "1",
  page_size: "10",
  sort_by: SortBy.email,
  sort_order: SortOrder.asc,
}

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#3EAA89",
            color: "#fff",
            border: "none",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#3EAA89",
            },
          },
        },
        previousNext: {
          border: "none",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#3EAA89",
          },
        },
      },
    },
  },
})

const UsersPanel = (): JSX.Element => {
  const [params, setParams] = useState<UsersParams>(DEFAULT_PARAMS)
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>({
    sort_by: DEFAULT_PARAMS.sort_by,
    sort_order: DEFAULT_PARAMS.sort_order,
  })

  const { t } = useTranslation()
  const { chatbotName, tenantName } = useChatbotContext()

  const { isLoading, data: usersData } = useUsersQuery(
    chatbotName,
    tenantName,
    params,
    {
      enabled: Boolean(chatbotName),
    },
  )

  useEffect(() => {
    if (
      selectedSortOption.sort_by !== params.sort_by ||
      selectedSortOption.sort_order !== params.sort_order
    ) {
      setParams({
        ...params,
        sort_by: selectedSortOption.sort_by,
        sort_order: selectedSortOption.sort_order,
      })
    }
  }, [params, selectedSortOption.sort_by, selectedSortOption.sort_order])

  const hasQuestionsClasses = "bg-white border-2 border-grey-900 border-solid"
  const emptyQuestionsClasses = "bg-grey-50 border border-color-1"

  const handleOnPaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setParams({ ...params, page: value.toString() })
  }

  const users = usersData ? [...usersData.users] : []
  const pageCount = getPaginationPages(usersData?.totalCount)

  return (
    <div
      className={cn(
        "flex flex-row rounded-3xl text-center sm:col-span-7 md:col-span-3",
        {
          [emptyQuestionsClasses]: Array.isEmptyArray(users),
          [hasQuestionsClasses]: !Array.isEmptyArray(users),
        },
      )}
    >
      <div className={cn("flex p-6 flex-col w-full")}>
        <div className="grid grid-cols-12 gap-4">
          <div
            className={cn("lg:order-1 sm:order-2 col-span-12 lg:col-span-12")}
          >
            <h1 className=" text-grey-100 text-2xl text-left px-6">
              {t("user_management.all_users", {
                usersCount: usersData?.totalCount ? usersData.totalCount : 0,
              })}
            </h1>
          </div>
        </div>
        <div
          className={cn("flex flex-col h-full items-center mt-6", {
            "justify-center": Array.isEmptyArray(users),
            "justify-between": !Array.isEmptyArray(users),
          })}
        >
          {!users.length && !isLoading && (
            <p className="text-grey-200">{t("user_management.no_user_data")}</p>
          )}

          <>
            {isLoading && (
              <div
                className={
                  "flex h-full items-center justify-center sm:col-span-7 md:col-span-3"
                }
              >
                <CircularProgress
                  sx={() => ({
                    color: "#3EAA89",
                  })}
                />
              </div>
            )}

            {Boolean(users.length) && (
              <UsersTable
                users={users}
                selectedSortOption={selectedSortOption}
                setSelectedSortOption={setSelectedSortOption}
              />
            )}

            {usersData && (
              <div className="flex justify-center">
                <ThemeProvider theme={theme}>
                  <Pagination
                    count={pageCount}
                    variant="outlined"
                    page={usersData.page}
                    onChange={handleOnPaginationChange}
                  />
                </ThemeProvider>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default UsersPanel
