{
  "ask_ai": {
    "back_to_all_questions": "Back To All Questions",
    "info": "You can ask the Ai Chat Bot anything about the files that are uploaded.",
    "placeholder": "Ask Ai here..."
  },
  "common": {
    "bot_answer": "Bot’s answer",
    "cancel": "Cancel",
    "chatbot": "Chatbot",
    "delete": "Delete",
    "no_data": "No data",
    "not_available": "N/A",
    "page_not_found": "Page not found.",
    "something_went_wrong": "Something went wrong",
    "user_question": "User’s question:"
  },
  "conversation": {
    "all_questions": "All questions ({{ questionsCount }})",
    "table": {
      "header": {
        "created_at": "Created At",
        "question": "Question",
        "rated": "Rated"
      }
    },
    "upload_new_file": "Upload a new file to load questions."
  },
  "date_filter": {
    "last_24_hours": "Last 24 Hours",
    "last_30_days": "Last 30 Days",
    "last_7_days": "Last 7 Days",
    "this_year": "Year"
  },
  "form_validation": {
    "email_invalid": "Enter a valid email",
    "field_invalid": "Field invalid",
    "field_required": "Field required",
    "number_invalid": "Must be a number",
    "confirm_password": "Passwords must match"
  },
  "header": {
    "profile": {
      "bot": "Bot",
      "dropdown": {
        "log_in": "Log In",
        "log_out": "Log Out",
        "settings": "Settings"
      },
      "hi": "Hi",
      "isop": "ISOP"
    },
    "tabs": {
      "home": "Home",
      "user_management": "User Management"
    }
  },
  "login_screen": {
    "email_address": "Email Address",
    "enter_password": "Enter your password",
    "enter_your_data": "Enter your data",
    "firstName": "First Name",
    "lastName": "Last Name",
    "next": "Next",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "sign_in_eva_dashboard": "Sign in to ChatBot Dashboard"
  },
  "modal": {
    "delete": "Are you sure you want to delete, {{ file }} file from the library?"
  },
  "uploadFile": {
    "button": "Upload File From PC",
    "no_files": "No Files have been uploaded.",
    "total_files_uploaded": "Total Files Uploaded",
    "uploaded": "File successfully uploaded.",
    "uploading": "New File Is being uploaded..."
  },
  "user_management": {
    "all_users": "Users ({{ usersCount }})",
    "no_user_data": "No users data",
    "table": {
      "header": {
        "account_type": "Account Type",
        "email": "Email",
        "name": "Name"
      }
    }
  }
}
