import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { EvolvAskIcon, HomeIcon, ISOPLogo, UserIcon } from "@app/assets/Icons"
import { useAuth0 } from "@auth0/auth0-react"
import cn from "classnames"

import ChatbotDropdown from "./ChatbotDropdown"
import ProfileHeader from "./ProfileHeader"

const Header = (): JSX.Element => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()
  const location = useLocation()

  const isActiveTab = (path: string): boolean =>
    location.pathname.startsWith(path)

  return (
    <div className="flex flex-row h-16 items-center justify-between px-20 pt-7 pb-3 lg:px-20">
      <div className="mr-6 hidden md:block">
        <Link to="/">
          <EvolvAskIcon className="" />
        </Link>
      </div>

      <div className="flex flex-row justify-start">
        <Link
          to="/dashboard"
          className={cn(
            "flex flex-row items-center py-3 px-5 rounded-[60px] cursor-pointer text-inherit no-underline",
            {
              "bg-grey-50": isActiveTab("/dashboard"),
            },
          )}
        >
          <HomeIcon className="mr-2" />
          <span>{t("header.tabs.home")}</span>
        </Link>
        <Link
          to="/user-management"
          className={cn(
            "flex flex-row items-center py-3 px-5 rounded-[60px] cursor-pointer text-inherit no-underline",
            {
              "bg-grey-50": isActiveTab("/user-management"),
            },
          )}
        >
          <UserIcon className="mr-2" />
          <span>{t("header.tabs.user_management")}</span>
        </Link>
      </div>

      {isAuthenticated ? (
        <ChatbotDropdown />
      ) : (
        <div data-testid="logo-container" className="mr-6 flex items-center">
          <ISOPLogo className="mr-4" />
          <span className="font-bold px-2 text-xl">
            {t("header.profile.isop")}
          </span>
          <span className="text-xl font-medium">{t("header.profile.bot")}</span>
        </div>
      )}

      <div className="">
        <ProfileHeader />
      </div>
    </div>
  )
}

export default Header
