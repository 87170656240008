export const specialCharacters = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-.:_]).{1,}$/
export const upperCase = /[A-Z]/
export const passwordLength = /^.{8,}$/
export const zipCode = /^\d{5}$/

export const checkIsImage = (value: string): boolean => {
  return /(imageUri).*.(gif|jpe?g|tiff?|png|webp|bmp|heif|avif|heic|dng).*.(})/i.test(
    value,
  )
}

export const isWhitespaceString = (value: string): boolean => {
  return !value.replace(/\s/g, "").length
}

export const containsAreaCode = (value: string): boolean => {
  return /(.?\d){4,}/.test(value)
}
