import { UserDetails } from "@app/model/user"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = UserDetails
type QueryKeyType = ReturnType<typeof QueryKey.userDetails>

export const getUserDetails: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, { role_id }] = queryKey
  const token = meta?.token as string
  const paramsString = role_id
    ? `?${new URLSearchParams({ role_id }).toString()}`
    : ""

  const path = `${REST_URL}${API_V1}/users/me${paramsString}`

  const response = await fetch(path, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("Get user details fails")
  }

  const results = (await response.json()) as QueryResultData
  return results
}

export const useMeQuery = (
  role_id: string,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.userDetails(role_id)
  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getUserDetails,
    options,
  )
}
