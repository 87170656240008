import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFilesQuery } from "@app/api/files/useFilesQuery"
import { useQuestionsQuery } from "@app/api/questions/useQuestionsQuery"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { DateFilter } from "@app/model/dateFilter"
import { QuestionsParams } from "@app/model/questions"
import { SortOption, SortOrder } from "@app/model/sorting"
import { getPaginationPages } from "@app/services/data"
import { ThemeProvider } from "@emotion/react"
import { CircularProgress, createTheme, Pagination } from "@mui/material"
import cn from "classnames"
import { Array, pipe } from "effect"

import Filters from "./Filters"
import QuestionDetails from "./QuestionDetails"
import QuestionsTable from "./QuestionsTable"

const DEFAULT_PARAMS: QuestionsParams = {
  page: "1",
  page_size: "10",
  timeframe: DateFilter.Last30Days,
  sort_by: "rated",
  sort_order: SortOrder.desc,
}

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#3EAA89",
            color: "#fff",
            border: "none",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#3EAA89",
            },
          },
        },
        previousNext: {
          border: "none",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#3EAA89",
          },
        },
      },
    },
  },
})

const ConversationPanel = (): JSX.Element => {
  const [params, setParams] = useState<QuestionsParams>(DEFAULT_PARAMS)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<DateFilter>(
    DEFAULT_PARAMS.timeframe,
  )
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>({
    sort_by: DEFAULT_PARAMS.sort_by,
    sort_order: DEFAULT_PARAMS.sort_order,
  })

  const [selectedQuestionId, setSelectedQuestionId] = useState<string>("")

  const { t } = useTranslation()
  const { chatbotName } = useChatbotContext()

  const { isLoading: isQuestionsLoading, data: questionsData } =
    useQuestionsQuery(chatbotName, params, {
      enabled: Boolean(chatbotName),
    })

  const { isLoading: isFilesLoading, data: listFiles } = useFilesQuery(
    chatbotName,
    {
      enabled: false,
    },
  )

  useEffect(() => {
    if (selectedTimeFrame !== params.timeframe) {
      setParams({ ...params, timeframe: selectedTimeFrame })
    }
    if (
      selectedSortOption.sort_by !== params.sort_by ||
      selectedSortOption.sort_order !== params.sort_order
    ) {
      setParams({
        ...params,
        sort_by: selectedSortOption.sort_by,
        sort_order: selectedSortOption.sort_order,
      })
    }
  }, [
    params,
    selectedTimeFrame,
    selectedSortOption.sort_by,
    selectedSortOption.sort_order,
  ])

  const hasQuestionsClasses = "bg-white border-2 border-grey-900 border-solid"
  const emptyQuestionsClasses = "bg-grey-50 border border-color-1 p-6"

  const handleOnPaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setParams({ ...params, page: value.toString() })
  }

  if (isFilesLoading) {
    return (
      <div
        className={
          "flex h-full items-center justify-center sm:col-span-7 md:col-span-3"
        }
      >
        <CircularProgress
          sx={() => ({
            color: "#3EAA89",
          })}
        />
      </div>
    )
  }

  const questions = questionsData ? [...questionsData.questions] : []
  const files = listFiles ? [...listFiles.files] : []
  const pageCount = getPaginationPages(questionsData?.totalCount)

  return (
    <div
      className={cn(
        "flex flex-row rounded-3xl text-center sm:col-span-7 md:col-span-3",
        {
          [emptyQuestionsClasses]: Array.isEmptyArray(files),
          [hasQuestionsClasses]: !Array.isEmptyArray(files),
        },
      )}
    >
      <div
        className={cn("flex p-6 flex-col", {
          "w-full": !selectedQuestionId,
          "w-[50%]": Boolean(selectedQuestionId),
        })}
      >
        <div className="flex justify-between h-16 px-10">
          <h1 className="text-grey-100 text-2xl mr-4">
            {t("conversation.all_questions", {
              questionsCount:
                files.length && questionsData?.totalCount
                  ? questionsData.totalCount
                  : 0,
            })}
          </h1>
          {!selectedQuestionId && (
            <>
              {pipe(
                files,
                Array.match({
                  onEmpty: () => <></>,
                  onNonEmpty: () => (
                    <Filters
                      selectedTimeFrame={selectedTimeFrame}
                      setSelectedTimeFrame={setSelectedTimeFrame}
                    />
                  ),
                }),
              )}

              <div className="flex items-center">
                {t("conversation.ask_ai")}
              </div>
            </>
          )}
        </div>
        <div
          className={cn("flex flex-col h-full  items-center mt-6", {
            "justify-center": Array.isEmptyArray(files),
            "justify-between": !Array.isEmptyArray(files),
          })}
        >
          {pipe(
            files,
            Array.match({
              onEmpty: () => (
                <p className="text-grey-200">
                  {t("conversation.upload_new_file")}
                </p>
              ),

              onNonEmpty: () => (
                <>
                  {isQuestionsLoading ? (
                    <div
                      className={
                        "flex h-full items-center justify-center sm:col-span-7 md:col-span-3"
                      }
                    >
                      <CircularProgress
                        sx={() => ({
                          color: "#3EAA89",
                        })}
                      />
                    </div>
                  ) : (
                    pipe(
                      questions,
                      Array.match({
                        onEmpty: () => (
                          <p className="text-grey-200">{t("common.no_data")}</p>
                        ),
                        onNonEmpty: () => (
                          <QuestionsTable
                            questions={questions}
                            selectedSortOption={selectedSortOption}
                            setSelectedQuestionId={setSelectedQuestionId}
                            setSelectedSortOption={setSelectedSortOption}
                          />
                        ),
                      }),
                    )
                  )}
                  {questionsData && (
                    <div className="flex justify-center">
                      <ThemeProvider theme={theme}>
                        <Pagination
                          count={pageCount}
                          variant="outlined"
                          page={questionsData.page}
                          onChange={handleOnPaginationChange}
                        />
                      </ThemeProvider>
                    </div>
                  )}
                </>
              ),
            }),
          )}
        </div>
      </div>
      <QuestionDetails
        id={selectedQuestionId}
        onClose={setSelectedQuestionId}
      />
    </div>
  )
}

export default ConversationPanel
