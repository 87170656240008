import React, { createContext, ReactNode, useContext, useState } from "react"

const ChatbotContext = createContext<
  | {
      chatbotName: string
      tenantName: string
      setChatbotName: (path: string) => void
      setTenantName: (tenantName: string) => void
    }
  | undefined
>(undefined)

const ChatbotProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [chatbotName, setChatbotName] = useState<string>("")
  const [tenantName, setTenantName] = useState<string>("")

  return (
    <ChatbotContext.Provider
      value={{ chatbotName, setChatbotName, tenantName, setTenantName }}
    >
      {children}
    </ChatbotContext.Provider>
  )
}

const useChatbotContext = (): {
  chatbotName: string
  tenantName: string
  setTenantName: (tenantName: string) => void
  setChatbotName: (chatbot: string) => void
} => {
  const context = useContext(ChatbotContext)
  if (!context) {
    throw new Error("useChatbotContext must be used within an ChatbotProvider")
  }
  return context
}

export { ChatbotProvider, useChatbotContext }
