import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { ChevronDown } from "@app/assets/Icons"
import { SortOption, SortOrder } from "@app/model/sorting"
import { User } from "@app/model/user"
import {
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from "@mui/material"
import cn from "classnames"

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
          border: 0,
        },
        head: {
          fontWeight: "bold",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiTableCell-root": {
              color: "#3EAA89",
            },
            "& .vote-icon": {
              background: "#3EAA89",
            },
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "inherit",
          },
        },
      },
    },
  },
})

type UsersTableProps = {
  users: User[]
  selectedSortOption: SortOption
  setSelectedSortOption: Dispatch<SetStateAction<SortOption>>
}

const UsersTable = ({
  users,
  selectedSortOption,
  setSelectedSortOption,
}: UsersTableProps): JSX.Element => {
  const { t } = useTranslation()

  const handleSort = (property: string): void => {
    const isAsc =
      selectedSortOption.sort_by === property &&
      selectedSortOption.sort_order === SortOrder.asc
    setSelectedSortOption({
      sort_by: property,
      sort_order: isAsc ? SortOrder.desc : SortOrder.asc,
    })
  }

  const cellHeadClasses =
    " whitespace-nowrap text-grey-100 border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5"
  const cellBodyClasses =
    "flex items-center border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5 h-[56px]"
  const cellIconClasses =
    "flex justify-center items-center border border-grey-100 border-solid m-auto w-[22px] h-[22px] rounded-[50%]"

  const textEllipsisClasses = "whitespace-nowrap overflow-hidden text-ellipsis"

  const tableHeaderConfig = [
    {
      key: "email",
      name: t("user_management.table.header.email"),
      sorter: true,
    },
    {
      key: "full_name",
      name: t("user_management.table.header.name"),
      sorter: true,
    },
    {
      key: "account_type",
      name: t("user_management.table.header.account_type"),
      sorter: true,
    },
  ]

  return (
    <div className="w-full px-6 mb-6">
      <ThemeProvider theme={theme}>
        <TableContainer component={Paper}>
          <Table className="min-w-[1000px]">
            <TableHead className="text-grey-100">
              <TableRow>
                <TableCell />
                {tableHeaderConfig.map(item => (
                  <TableCell key={item.key} className="font-bold">
                    {item.sorter ? (
                      <div className={cellHeadClasses}>
                        <TableSortLabel
                          active={selectedSortOption.sort_by === item.key}
                          direction={
                            selectedSortOption.sort_by === item.key
                              ? selectedSortOption.sort_order
                              : SortOrder.asc
                          }
                          onClick={() => handleSort(item.key)}
                          IconComponent={ChevronDown}
                        >
                          <span className="mr-5">{item.name}</span>
                        </TableSortLabel>
                      </div>
                    ) : (
                      <div className={cellHeadClasses}>{item.name}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell className="w-[26px]">
                    <div className={cn(cellIconClasses, "count-icon")}>
                      {++index}
                    </div>
                  </TableCell>
                  <TableCell className="">
                    <div className={cn(cellBodyClasses)}>
                      <span
                        className={cn("max-w-[300px]", textEllipsisClasses)}
                      >
                        {user.email}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={cellBodyClasses}>
                      <span
                        className={cn("max-w-[120px]", textEllipsisClasses)}
                      >
                        {user.userName || "-"}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={cellBodyClasses}>
                      <span
                        className={cn("max-w-[120px]", textEllipsisClasses)}
                      >
                        {user.accountType}
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  )
}

export default UsersTable
