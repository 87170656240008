import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { ChevronDown, Eye, Like, Unlike } from "@app/assets/Icons"
import { Question } from "@app/model/questions"
import { SortOption, SortOrder } from "@app/model/sorting"
import { formatDate } from "@app/services/formatDate"
import {
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from "@mui/material"
import cn from "classnames"

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
          border: 0,
        },
        head: {
          fontWeight: "bold",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiTableCell-root": {
              color: "#3EAA89",
            },
            "& .eye-icon": {
              display: "block",
            },
            "& .count-icon": {
              display: "none",
            },
            "& .vote-icon": {
              background: "#3EAA89",
            },
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "inherit",
          },
        },
      },
    },
  },
})

type QuestionsTableProps = {
  questions: Question[]
  selectedSortOption: SortOption
  setSelectedSortOption: Dispatch<SetStateAction<SortOption>>
  setSelectedQuestionId: Dispatch<SetStateAction<string>>
}

const QuestionsTable = ({
  questions,
  selectedSortOption,
  setSelectedSortOption,
  setSelectedQuestionId,
}: QuestionsTableProps): JSX.Element => {
  const { t } = useTranslation()

  const handleSort = (property: string): void => {
    const isAsc =
      selectedSortOption.sort_by === property &&
      selectedSortOption.sort_order === SortOrder.asc
    setSelectedSortOption({
      sort_by: property,
      sort_order: isAsc ? SortOrder.desc : SortOrder.asc,
    })
  }

  const cellHeadClasses =
    " whitespace-nowrap text-grey-100 border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5"
  const cellBodyClasses =
    "flex items-center border-b border-l-0 border-r-0 border-t-0 border-grey-100 border-solid py-4 px-3 mx-5 h-[56px]"
  const cellIconClasses =
    "flex justify-center items-center border border-grey-100 border-solid m-auto w-[22px] h-[22px] rounded-[50%]"

  const textEllipsisClasses = "whitespace-nowrap overflow-hidden text-ellipsis"

  const tableHeaderConfig = [
    {
      key: "question",
      name: t("conversation.table.header.question"),
      sorter: false,
    },
    {
      key: "created",
      name: t("conversation.table.header.created_at"),
      sorter: true,
    },
    {
      key: "rated",
      name: t("conversation.table.header.rated"),
      sorter: true,
    },
  ]

  return (
    <div className="w-full px-6 mb-6">
      <ThemeProvider theme={theme}>
        <TableContainer component={Paper}>
          <Table className="min-w-[1000px]">
            <TableHead className="text-grey-100">
              <TableRow>
                <TableCell />
                {tableHeaderConfig.map(item => (
                  <TableCell key={item.key} className="font-bold">
                    {item.sorter ? (
                      <div className={cellHeadClasses}>
                        <TableSortLabel
                          active={selectedSortOption.sort_by === item.key}
                          direction={
                            selectedSortOption.sort_by === item.key
                              ? selectedSortOption.sort_order
                              : SortOrder.asc
                          }
                          onClick={() => handleSort(item.key)}
                          IconComponent={ChevronDown}
                        >
                          <span className="mr-5">{item.name}</span>
                        </TableSortLabel>
                      </div>
                    ) : (
                      <div className={cellHeadClasses}>{item.name}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((question, index) => (
                <TableRow
                  key={question.id}
                  onClick={() => setSelectedQuestionId(question.id)}
                >
                  <TableCell className="w-[26px]">
                    <Eye className="w-[26px] hidden eye-icon" />
                    <div className={cn(cellIconClasses, "count-icon")}>
                      {++index}
                    </div>
                  </TableCell>
                  <TableCell className="">
                    <div className={cn(cellBodyClasses)}>
                      <span
                        className={cn("max-w-[300px]", textEllipsisClasses)}
                      >
                        {question.question}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={cellBodyClasses}>
                      <span
                        className={cn("max-w-[120px]", textEllipsisClasses)}
                      >
                        {formatDate(question.createdAt)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={cellBodyClasses}>
                      {question?.rating ? (
                        <>
                          <div
                            className={cn(
                              "vote-icon flex items-center justify-center w-[30px] h-[30px] rounded-[50%] mr-3 bg-grey-900",
                            )}
                          >
                            {question.rating.vote === "up" ? (
                              <Like className="h-4 w-4" />
                            ) : (
                              <Unlike className="h-4 w-4" />
                            )}
                          </div>
                          <span
                            className={cn("max-w-[200px]", textEllipsisClasses)}
                          >
                            {question.rating.comment}
                          </span>
                        </>
                      ) : (
                        t("common.not_available")
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  )
}

export default QuestionsTable
