import * as Yup from "yup"

import { passwordLength, specialCharacters, upperCase } from "./regexUtils"

export const testPassword = (value: string): Yup.ValidationError | boolean => {
  const validationRegex: { [key: string]: RegExp } = {
    special: specialCharacters,
    uppercase: upperCase,
    short: passwordLength,
  }

  const errors: string[] = Object.keys(validationRegex)
    .map(key => {
      const regex = validationRegex[key]
      return regex?.test(value) ? null : key
    })
    .filter(Boolean) as string[]

  if (errors.length === 0) {
    return true
  }

  const validationError: Yup.ValidationError = {
    errors,
    inner: [],
    path: "password",
    message: errors.join(),
    value,
    name: "ValidationError",
    [Symbol.toStringTag]: "ValidationError",
  }

  return new Yup.ValidationError(validationError)
}
