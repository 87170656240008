import { CreatedUser, InviteUserParams } from "@app/model/user"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

const inviteUser = async (
  role_id: string = "",
  data: InviteUserParams,
  token: string,
): Promise<CreatedUser> => {
  const paramsString = role_id
    ? `?${new URLSearchParams({ role_id }).toString()}`
    : ""

  const path = `${REST_URL}${API_V1}/invitation/user/create${paramsString}`
  const response = await fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(token),
    },
    body: JSON.stringify({
      ...data,
    }),
  })

  if (!response.ok) {
    throw new Error("Invite user failed")
  }

  const results = (await response.json()) as CreatedUser
  return results
}

export const useUserCreateQuery = (
  role_id?: string,
  options?: UseMutationOptions<CreatedUser, Error, InviteUserParams>,
): UseMutationResult<CreatedUser, Error, InviteUserParams> => {
  return useAuthenticatedMutation<CreatedUser, Error, InviteUserParams>(
    async (data, token) => inviteUser(role_id, data, token),
    options,
  )
}
