import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useMeQuery } from "@app/api/users/useMeQuery"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { AuthUser } from "@app/model/auth"
import { convertToTitleCase } from "@app/services/data"
import { useAuth0 } from "@auth0/auth0-react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

const ChatbotDropdown = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useAuth0<AuthUser>()
  const { chatbotName, setChatbotName, setTenantName } = useChatbotContext()

  const { data, isSuccess } = useMeQuery("", {
    enabled: Boolean(user),
  })

  const roles = data?.roles
    ?.map(role => role?.chatbot?.externalId)
    .filter(Boolean)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedRoles = useMemo(() => roles, [JSON.stringify(roles)])

  useEffect(() => {
    if (data?.tenantName) {
      setTenantName(data.tenantName)
    }
  }, [data?.tenantName, setTenantName])

  useEffect(() => {
    if (!chatbotName && memoizedRoles?.length) {
      setChatbotName(memoizedRoles[0] ?? "")
    }
    if (!memoizedRoles?.length && isSuccess) {
      console.error("There are no roles assigned to this user.")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedRoles])

  const handleChange = (event: SelectChangeEvent): void => {
    setChatbotName(event.target.value)
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">
        {t("common.chatbot")}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={chatbotName}
        onChange={handleChange}
        label="Chatbot"
      >
        {roles?.map((role: string | undefined) => {
          if (!role) return null
          return (
            <MenuItem key={role} value={role}>
              {convertToTitleCase(role)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ChatbotDropdown
