import React, { KeyboardEvent } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useUserCreateQuery } from "@app/api/invitation/useUserCreateQuery"
import { Email, EvolvLogo } from "@app/assets/Icons"
import { localizeYupSchema } from "@app/i18n/yup"
import FormSubmitButton from "@app/uiKit/FormSubmitButton"
import cn from "classnames"
import { FormikErrors, FormikHandlers, useFormik } from "formik"
import * as yup from "yup"

const Settings = (): JSX.Element => {
  const { t } = useTranslation()
  const containerClassName = cn(
    "w-[30%] rounded-[32px] bg-grey-50 p-6 sm:py-12 sm:px-16",
  )

  const { data: invitedUser, mutateAsync } = useUserCreateQuery()

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleLogin = async ({ email }: FormValues): Promise<void> => {
    if (email) {
      await mutateAsync({
        email,
        tenantName: "evolvtechnology.com",
        chatbots: ["evolv_hospital"],
      })
    }
  }

  const validationSchema = localizeYupSchema<FormValues>(
    t,
    yup.object({
      email: yup.string().email().required(),
    }),
  )

  const formikProps = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleLogin,
    validateOnChange: false,
  })
  return (
    <>
      <div
        data-testid="login-logo-container"
        className="flex h-16 items-center justify-center pt-7 w-full"
      >
        <Link to="/">
          <EvolvLogo className="cursor-pointer" />
        </Link>
      </div>

      {invitedUser && <InvitedUserScreen />}

      <div className="flex w-full h-full items-center justify-center">
        <div className={containerClassName}>
          <div className="text-xl sm:text-2xl font-medium pb-10">
            {"Invite user via email"}
          </div>
          <InputField {...formikProps} />
        </div>
      </div>
    </>
  )
}

const InvitedUserScreen = (): JSX.Element => {
  return (
    <div className="mt-10 text-center">
      {"The invitation link was sent! Please check your email."}
    </div>
  )
}

type FormValues = {
  email?: string
}

const initialValues: FormValues = {
  email: "",
}

type EmailInputProps = {
  values: FormValues
  errors: FormikErrors<FormValues>
  dirty: boolean
  isSubmitting: boolean
  handleChange: FormikHandlers["handleChange"]
  handleSubmit: FormikHandlers["handleSubmit"]
}

const InputField = ({
  values,
  dirty,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
}: EmailInputProps): JSX.Element => {
  const { t } = useTranslation()

  const emailIsNotEmpty = values.email

  const errorMessage = errors?.email
  const hasError = Boolean(errorMessage)

  const iconClassName = emailIsNotEmpty
    ? hasError
      ? "stroke-red-500"
      : "stroke-grey-900"
    : "stroke-grey-900"

  const isSubmitDisabled = !dirty || isSubmitting

  const handleLogin = (): void => {
    handleSubmit()
  }

  const inputClassName = cn(
    "block bg-grey-50 border-none h-12 focus:outline-none sm:w-full ml-11 text-grey-900 custom-input",
    { "text-red-500": hasError },
  )

  const inputContainerClassName = cn(
    "relative w-full border-b border-solid border-x-0 border-t-0",
    { "border-red-500": hasError, "border-stone-500": !hasError },
  )

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  return (
    <>
      <div className="pb-10">
        <div className="h-3 -mt-1">
          {emailIsNotEmpty && (
            <label htmlFor="emailAddress" className="text-xs block">
              {t("login_screen.email_address")}
            </label>
          )}
        </div>
        <div className={inputContainerClassName}>
          <div className="absolute mt-3 ml-3">
            <Email className={iconClassName} />
          </div>
          <input
            id="emailAddress"
            type="email"
            value={values.email}
            autoComplete="off"
            className={inputClassName}
            placeholder={t("login_screen.email_address")}
            onChange={handleChange("email")}
            onKeyDown={handleKeyDown}
          />
        </div>
        {hasError && (
          <div className={"min-h-[18px] text-left mt-2 text-red-500 text-xs"}>
            {errorMessage}
          </div>
        )}
      </div>
      <FormSubmitButton
        testID="login-next-button"
        onPress={handleLogin}
        isLoading={isSubmitting}
        isDisabled={isSubmitDisabled}
        text={"Invite"}
      />
    </>
  )
}

export default Settings
