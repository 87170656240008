import React from "react"
import UploadFile from "@app/uiKit/UploadFile"

import ConversationPanel from "./ConversationPanel"
import ListFiles from "./ListFiles"

const Dashboard = (): JSX.Element => {
  return (
    <div
      data-cy="chatbot-page"
      className="grid h-full gap-4 p-4 sm:grid-flow-col sm:grid-cols-10 md:grid-cols-4"
    >
      <div className="flex flex-col border-color-1 bg-grey-50 rounded-3xl border p-6 text-center sm:col-span-3 md:col-span-1">
        <UploadFile />
        <ListFiles />
      </div>

      <ConversationPanel />
    </div>
  )
}

export default Dashboard
