import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDeleteQuery } from "@app/api/files/useDeleteQuery"
import { useDownloadQuery } from "@app/api/files/useDownload"
import { useFilesQuery } from "@app/api/files/useFilesQuery"
import { Download, File, Trash, Upload } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"
import Modal from "@app/uiKit/Modal"
import { CircularProgress } from "@mui/material"
import cn from "classnames"
import { Array, pipe } from "effect"

const ListFiles = (): JSX.Element => {
  const [openModal, setModalOpen] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<string>("")
  const { chatbotName } = useChatbotContext()

  const handleModalClose = (): void => setModalOpen(false)

  const { t } = useTranslation()

  const { isLoading, isFetching, data, error } = useFilesQuery(chatbotName, {
    enabled: Boolean(chatbotName),
  })

  const { mutate: mutateDownload } = useDownloadQuery(chatbotName)
  const { isPending: isDeletePending, mutate: mutateDelete } =
    useDeleteQuery(chatbotName)

  if (isLoading || isFetching || isDeletePending) {
    return (
      <div className={"flex flex-col h-full items-center mt-6 justify-center"}>
        <CircularProgress
          sx={() => ({
            color: "#3EAA89",
          })}
        />
      </div>
    )
  }

  if (error) {
    return <div>{t("common.something_went_wrong")}</div>
  }

  if (!data) return <></>

  const files = [...data.files]

  const handleOnDownload = (key: string): void => {
    mutateDownload(key)
  }

  const handleOnDelete = (key: string): void => {
    setModalOpen(true)
    setSelectedFile(key)
  }

  const handleModalDelete = (): void => {
    mutateDelete(selectedFile)
    handleModalClose()
  }

  return (
    <>
      <div
        className={cn("flex flex-col h-full items-center mt-6", {
          "justify-center": Array.isEmptyArray(files),
        })}
      >
        {pipe(
          files,
          Array.match({
            onEmpty: () => (
              <>
                <Upload className="h-8 w-8 text-grey-200" />
                <p className="text-grey-200"> {t("uploadFile.no_files")}</p>
              </>
            ),
            onNonEmpty: () => (
              <>
                <div className="border-solid border-0 border-b border-grey-100 text-left py-2 w-full">
                  <span className="text-grey-100">{`${t("uploadFile.total_files_uploaded")} (${files.length})`}</span>
                </div>
                <ul className="list-none text-left text-grey-900 p-0 w-full h-[32rem] overflow-y-auto -mr-3.5 pr-3.5">
                  {files.map((file, i) => {
                    return (
                      <li key={i} className="flex items-center py-2">
                        <File className="h-6 w-6 mr-3" />
                        <span className="w-full cursor-pointer hover:text-green-50 mr-3 whitespace-nowrap overflow-hidden text-ellipsis">
                          {file.key}
                        </span>
                        <div
                          className="items-center flex h-8 w-8 mr-3 cursor-pointer"
                          onClick={() => handleOnDownload(file.key)}
                        >
                          <Download className="stroke-grey-900 hover:stroke-green-50" />
                        </div>
                        <div
                          className="items-center flex h-6 w-6 cursor-pointer"
                          onClick={() => handleOnDelete(file.key)}
                        >
                          <Trash className="h-8 w-8 cursor-pointer hover:text-red-50" />
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </>
            ),
          }),
        )}
      </div>
      <Modal open={openModal} onClose={handleModalClose}>
        <div className="flex flex-col items-center py-6 px-14">
          <Trash className="h-8 w-8 text-grey-900" />
          <p className="text-center text-xl mt-10">
            {t("modal.delete", { file: selectedFile })}
          </p>
          <div className="mt-10">
            <button
              type="button"
              className="bg-grey-900 text-white text-xl rounded-[60px] px-10 py-4 border-none mr-4 cursor-pointer"
              onClick={handleModalClose}
            >
              {t("common.cancel")}
            </button>
            <button
              type="button"
              className="bg-grey-900 text-white text-xl rounded-[60px] px-10 py-4 border-none cursor-pointer"
              onClick={handleModalDelete}
            >
              {t("common.delete")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ListFiles
