import React from "react"
import { useTranslation } from "react-i18next"
import { useQuestionQuery } from "@app/api/questions/useQuestionQuery"
import { Close, Like, Unlike } from "@app/assets/Icons"
import { formatDate } from "@app/services/formatDate"
import { CircularProgress, Slide } from "@mui/material"
import cn from "classnames"

type QuestionDetailsProps = {
  id: string
  onClose: (id: string) => void
}

const QuestionDetails = (props: QuestionDetailsProps): JSX.Element => {
  const { id, onClose } = props
  const { t } = useTranslation()

  const { isFetching, data, error } = useQuestionQuery(id, {
    enabled: Boolean(id),
  })

  if (error) {
    return <div>{t("common.something_went_wrong")}</div>
  }

  const isVoteUp = data?.rating?.vote === "up"

  return (
    <Slide direction="left" in={Boolean(id)} mountOnEnter unmountOnExit>
      <div className="flex text-grey-900 flex-col p-12 w-full bg-gradient-to-b from-[#D5E8E1] to-[#B1E1D5] rounded-3xl border-2 border-grey-900 border-solid relative top-[-2px] right-[-2px] h-[calc(100%+4px)]">
        <div className="flex ml-auto" onClick={() => onClose("")}>
          <Close className="cursor-pointer" />
        </div>
        {isFetching || !id ? (
          <div className={"flex h-full items-center justify-center"}>
            <CircularProgress
              sx={() => ({
                color: "#3EAA89",
              })}
            />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center py-6 border-b border-l-0 border-r-0 border-t-0 border-grey-900 border-solid">
              <div className="text-sm">
                {data?.createdAt && formatDate(data.createdAt, true)}
              </div>
            </div>
            <div className="flex flex-col text-left">
              <div>
                <p className="text-sm text-grey-200">
                  {t("common.user_question")}
                </p>
                <div className="bg-white p-4 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl">
                  {data?.question}
                </div>
              </div>
              {data?.answer && (
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-sm text-grey-200">
                      {t("common.bot_answer")}
                    </p>
                    {data?.rating ? (
                      <div className="flex items-center">
                        <div
                          className={cn(
                            "vote-icon flex items-center justify-center w-[30px] h-[30px] rounded-[50%] mr-3",
                            {
                              "bg-green-50": isVoteUp,
                              "bg-red-50": !isVoteUp,
                            },
                          )}
                        >
                          {isVoteUp ? (
                            <Like className="h-4 w-4 stroke-grey-900" />
                          ) : (
                            <Unlike className="h-4 w-4 stroke-grey-900" />
                          )}
                        </div>
                        <span
                          className={cn("px-4 py-1 rounded-3xl ", {
                            "bg-green-50": isVoteUp,
                            "bg-red-50": !isVoteUp,
                          })}
                        >
                          {data?.rating?.comment}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="bg-grey-900 p-4 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl text-white">
                    {data.answer}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Slide>
  )
}

export default QuestionDetails
