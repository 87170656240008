import React from "react"
import { CircularProgress } from "@mui/material"
import cn from "classnames"

type FormSubmitButtonProps = {
  onPress: () => void
  isDisabled?: boolean
  isLoading?: boolean
  testID?: string
  text: string
}

const FormSubmitButton = ({
  onPress,
  text,
  isDisabled = false,
  isLoading = false,
  testID,
}: FormSubmitButtonProps): JSX.Element => {
  const buttonClassName = cn(
    "w-full rounded-full bg-emerald-300 border-none focus:outline-none py-3 justify-end items-end",
    {
      "bg-grey-100 cursor-default": isDisabled,
      "bg-emerald-300 cursor-pointer": !isDisabled,
    },
  )

  return isLoading ? (
    <div className={"flex max-h-6 items-center justify-center"}>
      <CircularProgress
        size="24px"
        sx={{
          color: "#5DFFCE",
        }}
      />
    </div>
  ) : (
    <button
      type="submit"
      className={buttonClassName}
      onClick={onPress}
      data-testid={testID}
      disabled={isDisabled}
    >
      <span className="text-stone-900 font-medium">{text}</span>
    </button>
  )
}

export default FormSubmitButton
